import React from 'react';
import BookingCalendar from './components/BookingCalendar';

function App() {
  return (
    <div className="App">
      <BookingCalendar />
    </div>
  );
}

export default App;
