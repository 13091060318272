import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import '../BookingCalendar.css';
import axios from 'axios';
import { addMonths, addMinutes, format, addDays, startOfDay, setHours, setMinutes } from 'date-fns';
import { fi } from 'date-fns/locale';

const TIME_BLOCKS = [
    "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00"
];

const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

function BookingCalendar() {
  const today = startOfDay(new Date());
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [message, setMessage] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [occupiedSlots, setOccupiedSlots] = useState([]);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const [companyName, setCompanyName] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [companyIndustryError, setCompanyIndustryError] = useState('');

  const meetingDuration = 45; // Duration in minutes
  const minDate = addDays(new Date(), 2);
  const maxDate = addMonths(new Date(), 1);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchOccupiedSlots = useCallback(async (selectedDate) => {
      const formattedDate = formatDate(selectedDate);
      try {
        const response = await axios.get(`https://sivubisnes.net/api/occupied-slots?date=${formattedDate}`);
          
          // Ensure that response data is in the expected format
          if (response.data && Array.isArray(response.data)) {
              return response.data;
          } else {
              throw new Error('Unexpected response format');
          }
      } catch (error) {
          console.error('Error fetching occupied slots:', error);
          return [];
      }
  }, []);

  useEffect(() => {
    const updateOccupiedSlots = async () => {
      if (date) {
        const slots = await fetchOccupiedSlots(date);
        setOccupiedSlots(slots);
      }
    };
  
    updateOccupiedSlots();
  }, [date, fetchOccupiedSlots]);

  const handleBooking = () => {
    setShowSummary(true);
  };


  const handleConfirm = async () => {
    let hasError = false;

    if (!validateEmail(email)) {
      setEmailError('Pakollinen kenttä');
      hasError = true;
    } else {
      setEmailError('');
    }
    
    if (!companyName) {
      setCompanyNameError('Pakollinen kenttä');
      hasError = true;
    } else {
      setCompanyNameError('');
    }

    if (!companyIndustry) {
      setCompanyIndustryError('Yrityksen päätoimiala on pakollinen');
      hasError = true;
    } else {
      setCompanyIndustryError('');
    }

    if (hasError) return;

    setLoading(true); 
  
    const formattedDate = formatDate(date) + 'T' + time + ':00Z';
    try {
      await axios.post(`https://sivubisnes.net/api/book`, {
        date: formattedDate,
        time,
        email,
        companyName,
        companyIndustry
      });
      setMessage('Varausvahvistus on lähetetty sähköpostiisi');
      setBookingConfirmed(true);
    } catch (error) {
      console.error('There was an error making the booking!', error);
      const errorMessage = error.response?.data || 'Varauksen vahvistamisessa tapahtui virhe. Yritä myöhemmin uudelleen.';
      setMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  
  

  const tileDisabled = ({ date }) => {
    const isDisabled = isWeekend(date) || date < addDays(new Date(), 1) || date > addDays(new Date(), 30) || date.toDateString() === today.toDateString();
    return isDisabled;
  };
  
  const calculateEndTime = () => {
    if (!time) return '';

    const [hours, minutes] = time.split(':').map(Number);
    const startDateTime = setMinutes(setHours(date, hours), minutes);
    const endDateTime = addMinutes(startDateTime, meetingDuration);

    return format(endDateTime, 'HH:mm', { locale: fi });
  };

  const isSlotOccupied = (slot) => occupiedSlots.includes(slot);

  const getMessageClass = () => {
    if (message.includes('Syötetyllä sähköpostilla on jo aktiivinen varaus')) return 'error-message';
    if (message.includes('Varausvahvistus')) return 'success-message';
    return '';
  };


  return (
    <div className="calendar-container">
      {!showSummary ? (
        <>
          <h4 className="calendar-container-h4">Valitse sopiva päivä aloittaaksesi</h4>
          <span className="calendar-container-span">Konsultaation oletettu kesto on noin 45 minuuttia</span>
          <Calendar
            onChange={setDate}
            value={date}
            minDate={minDate}
            maxDate={maxDate}
            className="react-calendar"
            locale="fi"
            tileClassName={({ date }) => {
              const isDisabled = tileDisabled({ date });
              const isToday = date.toDateString() === today.toDateString();
              if (isToday) {
                return 'react-calendar__tile--today';
              }
              return isDisabled ? 'react-calendar__tile--disabled' : (isWeekend(date) ? 'react-calendar__tile--weekend' : null);
            }}
            tileDisabled={tileDisabled}
          />

          {date && (
            <div className="time-picker-container">
              <h3>Valitse sopiva aika päivälle <br /> {format(date, 'd. MMMM yyyy', { locale: fi })}</h3>
              <div className="time-options">
                {TIME_BLOCKS.map((block, index) => (
                  <button
                    key={index}
                    className={`time-option ${time === block ? 'selected' : ''} ${isSlotOccupied(block) ? 'occupied' : ''}`}
                    onClick={() => !isSlotOccupied(block) && setTime(block)}
                    disabled={isSlotOccupied(block)}
                  >
                    {block} {isSlotOccupied(block) && <span className="varattu-text"></span>}
                  </button>
                ))}
              </div>
            </div> 
          )}
          
          {time && (
            <button className="show-summary-button" onClick={handleBooking}>
              Yhteenveto
            </button>
          )}
        </>
      ) : (
        <div className="meeting-details">
          {!bookingConfirmed && (
            <button
              className="change-time-button"
              onClick={() => {
                setShowSummary(false);
                setEmail('');
                setCompanyName('');
                setCompanyIndustry('');
                setMessage('');
                setEmailError('');
                setCompanyNameError('');
                setCompanyIndustryError('');
              }}
            >
              <span className="change-time-button-arrow">&#9664;</span>
              Muuta ajankohtaa
            </button>
          )}
          <div className="meeting-details-summary">
            <h3>Varauksen yhteenveto</h3>
            <p>Palaveri varataan päivälle <span className="meeting-detail-highlight"> {format(date, 'd. MMMM yyyy', { locale: fi })}</span></p>
            <p>Palaverin aloitus: <span className="meeting-detail-highlight">{time}</span></p>
            <p>
              Palaverin arvioitu päättyminen: <span className="meeting-detail-highlight">
                {calculateEndTime()}
              </span>
            </p>
          </div>
          
          <div className="email-input">
            <label>Yrityksen nimi</label>
            <input
              type="text"
              placeholder="Syötä yrityksen nimi"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
            {companyNameError && <p className="error-message">{companyNameError}</p>}
          </div>

          <div className="email-input">
            <label>Yrityksen päätoimiala</label>
            <input
              type="text"
              placeholder="Syötä yrityksen päätoimiala"
              value={companyIndustry}
              onChange={(e) => setCompanyIndustry(e.target.value)}
              required
            />
            {companyIndustryError && <p className="error-message">{companyIndustryError}</p>}
          </div>
          
          <div className="email-input">
            <label>Syötä sähköpostiosoite saadaksesi varausvahvistus</label>
            <input
              type="email"
              placeholder="Syötä sähköpostiosoite"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailError && <p className="error-message">{emailError}</p>}
          </div>
          
          <button className="confirm-meeting-button" onClick={handleConfirm} disabled={loading}>
            {loading ? 'Käsitellään...' : 'Vahvista varaus'}
          </button>
        </div>
      )}
      {message && (
        <div className="submission-status">
          <p className={getMessageClass()}>{message}</p>
        </div>
      )}
    </div>
  );
}

export default BookingCalendar;
